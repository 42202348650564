import axios, { CancelTokenSource } from 'axios'

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { Agency } from '../../models/Agency.model'
import { AgencyDTO } from '../../models/AgencyDTO.model'
import { AgencyAddEvent } from '../../events/AgencyAddEvent'
import { AgencyUpdateEvent } from '../../events/AgencyUpdateEvent'

import ScrubstrApiQueryService, {
  ScrubstrApiQueryServiceGetEvent,
} from '../ScrubstrApiQueryService/ScrubstrApiQueryService'

export class AgencyService {
  private autocompleteAgenciesSource: CancelTokenSource | null = null

  /**
   * @description Search for a single agency by id or permalink
   *
   * @param id
   * @param permalink
   *
   * @returns promise for a Agency
   */
  public async searchOne({ id, permalink }: { id?: number; permalink?: string }): Promise<Agency> {
    const params = {
      id,
      permalink,
      excludeReviews: 'true', // always excluding reviews for the admin interface
    }
    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: `agency/search-one`,
      params,
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const agencyRaw = await scrubstrApiQueryService.get<AgencyDTO>(scrubstrApiQueryRequest)
      return this.formatAgency(agencyRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search({
    page,
    pageSize,
    term,
    orderBy,
    orderDirection,
    autocomplete,
  }: {
    page?: number
    pageSize?: number
    term: string
    orderBy?: keyof Agency
    orderDirection?: 'asc' | 'desc'
    autocomplete?: boolean
  }): Promise<{
    agencies: Agency[]
    totalResults: number
    totalPages: number
    currentPage: number
  }> {
    const params = {
      page,
      pageSize,
      orderBy,
      orderDirection: orderDirection?.toUpperCase(),
      name: term || undefined,
      excludeReviews: 'true', // always excluding reviews for the admin interface
    }

    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: 'agency',
      params,
    }

    if (autocomplete) {
      if (this.autocompleteAgenciesSource !== null) {
        this.autocompleteAgenciesSource.cancel('cancelToken triggered')
      }
      this.autocompleteAgenciesSource = axios.CancelToken.source()
      scrubstrApiQueryRequest.cancelToken = this.autocompleteAgenciesSource.token
    }

    const agencyResults: {
      agencies: Agency[]
      totalResults: number
      totalPages: number
      currentPage: number
    } = {
      agencies: [],
      totalResults: 0,
      totalPages: 0,
      currentPage: Number(page),
    }
    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const agenciesRaw = await scrubstrApiQueryService.get<PaginatedResponse<AgencyDTO>>(scrubstrApiQueryRequest)
      // wrap to avoid errors if the request is cancelled
      if (agenciesRaw) {
        agencyResults.agencies = agenciesRaw.data.map((agency) => this.formatAgency(agency))

        agencyResults.totalResults = agenciesRaw.total
        agencyResults.totalPages = Math.ceil(agenciesRaw.total / 12)
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return agencyResults
  }

  public async add(agency: AgencyAddEvent): Promise<Agency> {
    const scrubstrApiQueryRequest = {
      endpoint: 'agency',
      data: agency,
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const agencyRaw = await scrubstrApiQueryService.post<AgencyDTO>(scrubstrApiQueryRequest)
      return this.formatAgency(agencyRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, agency: AgencyUpdateEvent): Promise<Agency> {
    const scrubstrApiQueryRequest = {
      endpoint: `agency/${id}`,
      data: agency,
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const agencyRaw = await scrubstrApiQueryService.patch<AgencyDTO>(scrubstrApiQueryRequest)
      return this.formatAgency(agencyRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const scrubstrApiQueryRequest = {
      endpoint: `agency/${id}`,
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      await scrubstrApiQueryService.delete(scrubstrApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    agencies: Partial<Agency>[],
    options: {
      matchingField?: string | undefined
      deleteUnmatchedExisting: boolean
    },
  ): Promise<void> {
    const data = agencies.map((agency) => {
      return {
        ...agency,
        id: agency.id ? Number(agency.id) : undefined,
        averageRating: agency.averageRating ? Number(agency.averageRating) : undefined,
      }
    })
    const scrubstrApiQueryRequest = {
      endpoint: `agency/sync`,
      data: {
        data,
        matchingProp: options.matchingField,
        deleteMissing: options.deleteUnmatchedExisting,
      },
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      await scrubstrApiQueryService.post(scrubstrApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public formatAgency(agency: AgencyDTO): Agency {
    return {
      id: agency.id,
      name: agency.name,
      imageURL: agency.imageURL,
      permalink: agency.permalink,
      address: agency.address,
      website: agency.website,
      phone: agency.phone,
      email: agency.email,
      facebook: agency.facebook,
      externalId: agency.externalId,
      averageRating: agency.averageRating,
      updatedAt: agency.updatedAt,
      createdAt: agency.createdAt,
    }
  }
}
