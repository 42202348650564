import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material'

type BreadcrumbsProps = {
  items: Array<{ label: string; href?: string }>
}
const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { items } = props

  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/">
        Home
      </Link>
      {items.map((item) =>
        item.href ? (
          <Link color="inherit" href={item.href} key={`${item.label}_${item.href}`}>
            {item.label}
          </Link>
        ) : (
          <Typography color="text.primary" key={`${item.label}_nohref`}>
            {item.label}
          </Typography>
        ),
      )}
    </MUIBreadcrumbs>
  )
}
export default Breadcrumbs
