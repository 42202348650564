import { USStates } from './USStates.model'

export type Hospital = {
  id: number
  name: string
  imageURL: string | null
  permalink: string
  latitude: number | null
  longitude: number | null
  address: string | null
  city: string | null
  state: USStates
  zipcode: string | null
  website: string | null
  supportTelephone: string | null
  supportEmail: string | null
  externalId: string | null
  averageRating: number
  updatedAt: string
  createdAt: string
}

// we offer this to enable the enumerate the Hospital type's fields in js/ts files
export const hospitalFields: (keyof Hospital)[] = [
  'id',
  'name',
  'imageURL',
  'permalink',
  'latitude',
  'longitude',
  'address',
  'city',
  'state',
  'zipcode',
  'website',
  'supportTelephone',
  'supportEmail',
  'externalId',
  'averageRating',
  'updatedAt',
  'createdAt',
]
