import { useState } from 'react'

import { Agency } from '../../../models/Agency.model'
import { AgencyAddEvent } from '../../../events/AgencyAddEvent'
import { AgencyUpdateEvent } from '../../../events/AgencyUpdateEvent'

import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Button, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AgencyForm from './AgencyForm.component'

type AgencyFormDialogProps = {
  open: boolean
  agency?: Agency
  onAdd?: (agency: AgencyAddEvent, setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>) => void
  onEdit?: (agency: AgencyUpdateEvent, setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>) => void
  onCancel: () => void
  onClose: () => void
}
export const AgencyFormDialog = (props: AgencyFormDialogProps) => {
  const { agency, onCancel, onClose, onAdd, onEdit, open } = props

  // used to remotely trigger the save of the child form
  const [saveTrigger, setSaveTrigger] = useState(0)

  const handleSave = () => {
    setSaveTrigger((prev) => prev + 1)
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ mr: 2, flex: 1 }} variant="h6" component="div">
            {agency ? 'Edit' : 'Add'} Agency
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            Save
          </Button>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <AgencyForm agency={agency} onAdd={onAdd} onEdit={onEdit} onCancel={onCancel} saveTrigger={saveTrigger} />
      </DialogContent>
    </Dialog>
  )
}
export default AgencyFormDialog
