import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppLayoutContext } from '../../../providers/AppLayout'
import { useUrlStateParams } from '../../../utils/url'
import { AppConfig } from '../../App.config'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { Hospital } from '../../../models/Hospital.model'
import { HospitalUpdateEvent } from '../../../events/HospitalUpdateEvent'

import { HospitalService } from '../../../services/HospitalService/HospitalService'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs.component'
import HospitalFormDialog from '../shared/HospitalFormDialog.component'

const HospitalDetail = (): JSX.Element => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [isLoading, setIsLoading] = useState(true)
  const [loadingError, setLoadingError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [savingError, setSavingError] = useState('')
  const [isEditing, setIsEditing] = useUrlStateParams<boolean>(
    false,
    'edit',
    (value) => (value ? 't' : ''),
    (value) => value === 't',
  )
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
  const [hospital, setHospital] = useState<Hospital>()

  const hospitalService = useMemo(() => new HospitalService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    AppLayoutContext.setPageName(`Hospital Detail`)
  }, [hospital, id])

  useEffect(() => {
    setIsLoading(true)
    setLoadingError('')
    hospitalService
      .searchOne({ id: Number(id) })
      .then((result) => {
        setIsLoading(false)
        setHospital(result)
      })
      .catch((err) => {
        setLoadingError(err?.data?.message || 'Failed to load hospital')
        console.error(err)
        setIsLoading(false)
      })
  }, [id])

  const handleEdit = (
    changedProperties: HospitalUpdateEvent,
    setIsEditFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (!Object.keys(changedProperties).length) {
      // nothing changed so just close the dialog
      setIsEditing(false)
    } else {
      setIsSaving(true)
      setSavingError('')
      hospitalService
        .update(Number(id), changedProperties)
        .then((result) => {
          SnackbarContext.show(`Hospital saved successfully`)
          setIsSaving(false)
          setHospital(result)
          setIsEditing(false)
        })
        .catch((err) => {
          SnackbarContext.show(`Hospital failed to edit: ${err?.data?.message || err?.message}`, 'error')
          setSavingError(err)
          console.error(err)
          setIsSaving(false)
          setIsEditFormSubmitting(false)
        })
    }
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDelete = () => {
    if (hospital?.id) {
      setIsLoading(true)
      setIsDeleteConfirmationOpen(false)
      hospitalService
        .delete(hospital.id)
        .then(() => {
          SnackbarContext.show(`Hospital deleted successfully. You will now be redirected.`)

          // after a delay of 1 second, redirect to the hospital list page
          setTimeout(() => {
            navigate('/hospitals')
          }, 1000)
        })
        .catch((err) => {
          console.error(err)
          SnackbarContext.show(`Hospital failed to delete: ${err?.data?.message || err?.message}`, 'error')
          setIsLoading(false)
        })
    }
  }

  return (
    <>
      {hospital && (
        <HospitalFormDialog
          open={isEditing}
          hospital={hospital}
          onEdit={handleEdit}
          onCancel={() => setIsEditing(false)}
          onClose={() => setIsEditing(false)}
        />
      )}
      <Dialog
        fullWidth
        maxWidth="xs"
        open={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>Are you sure you want to delete this hospital?</DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setIsDeleteConfirmationOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ p: 2 }}>
        {isLoading && !loadingError && <Loading sx={{ py: 20 }} />}
        {loadingError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{loadingError}</Typography>
          </Stack>
        )}
        {!isLoading && !loadingError && hospital && (
          <>
            {isSaving && !savingError && <Loading sx={{ py: 20 }} />}
            {savingError && (
              <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
                <Typography variant="error">{savingError}</Typography>
              </Stack>
            )}
            {!isSaving && !savingError && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ mb: 2 }}>
                  <Breadcrumbs items={[{ label: 'Hospitals', href: '/hospitals' }, { label: hospital.name }]} />
                  <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                    <Button variant="text" onClick={handleDeleteClick}>
                      Delete
                    </Button>
                    <Button variant="contained" onClick={() => setIsEditing(true)}>
                      Edit
                    </Button>
                  </Stack>
                </Stack>
                <Box>
                  <Box sx={{ mb: 2 }}>
                    {hospital.imageURL && (
                      <img
                        src={hospital.imageURL}
                        alt="hospital logo"
                        style={{ width: '100%', maxWidth: 300, maxHeight: 300 }}
                      />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="h5" component="h2">
                      {hospital.name}
                    </Typography>
                    <Typography variant="subtitle1" color={(theme) => theme.palette.grey[600]}>
                      ID: {hospital.id}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h3">
                      In The App:
                    </Typography>
                    <Link
                      variant="body1"
                      href={`${AppConfig.companionAppUrl}/hospitals/${hospital.permalink}`}
                      target="_blank"
                    >
                      {AppConfig.companionAppUrl}/hospitals/{hospital.permalink}
                    </Link>
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h3">
                      Address
                    </Typography>
                    <Typography variant="body1">{hospital.address}</Typography>
                    <Typography variant="body1">
                      {hospital.city}, {hospital.state} {hospital.zipcode}
                    </Typography>
                    {hospital.latitude !== null && hospital.longitude !== null && (
                      <Typography variant="body1" color={(theme) => theme.palette.grey[600]} sx={{ mt: 1 }}>
                        {[hospital.latitude, hospital.longitude].join(', ')}
                      </Typography>
                    )}
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h3">
                      Support
                    </Typography>
                    {hospital.website && (
                      <Box>
                        <Link variant="body1" href={hospital.website} target="_blank">
                          {hospital.website}
                        </Link>
                      </Box>
                    )}
                    {hospital.supportTelephone && (
                      <Box sx={{ mt: 1 }}>
                        <Link variant="body1" href={`tel:${hospital.supportTelephone}`} target="_blank">
                          {hospital.supportTelephone}
                        </Link>
                      </Box>
                    )}
                    {hospital.supportEmail && (
                      <Box sx={{ mt: 1 }}>
                        <Link variant="body1" href={`mailto:${hospital.supportEmail}`} target="_blank">
                          {hospital.supportEmail}
                        </Link>
                      </Box>
                    )}
                  </Box>
                  <Divider sx={{ my: 2 }} />
                  <Box>
                    <Typography variant="h6" component="h3">
                      System
                    </Typography>
                    <Typography variant="body1">External ID: {hospital.externalId}</Typography>
                    <Typography variant="body1">Average Rating: {hospital.averageRating}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default HospitalDetail
