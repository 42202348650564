import { useEffect, useState } from 'react'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { generatePermalink } from '../../../utils/formatting'
import { AppConfig } from '../../App.config'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { Hospital } from '../../../models/Hospital.model'
import { USStates } from '../../../models/USStates.model'
import { HospitalAddEvent } from '../../../events/HospitalAddEvent'
import { HospitalUpdateEvent } from '../../../events/HospitalUpdateEvent'

import {
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Button,
  Select,
  MenuItem,
} from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'

type DetailInputProps = {
  label: string
  field: keyof Hospital
  type?: 'text' | 'number' | 'email' | 'url'
  required?: boolean
  placeholder?: string
  helperText?: string
  tempHospital: Partial<Hospital>
  setTempHospital: React.Dispatch<React.SetStateAction<Partial<Hospital>>>
  keyDownSubmit: (e: React.KeyboardEvent) => void
}
const DetailInput = (props: DetailInputProps) => {
  const {
    label,
    field,
    required = false,
    helperText,
    placeholder,
    type = 'text',
    tempHospital,
    setTempHospital,
    keyDownSubmit,
  } = props

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        id={`hospital-${field}`}
        label={label}
        value={tempHospital[field] || ''}
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={(e) => {
          setTempHospital((prev) => ({ ...prev, [field]: e.target.value }))
        }}
        onKeyDown={keyDownSubmit}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export type HospitalFormProps = {
  hospital?: Hospital
  onAdd?: (hospital: HospitalAddEvent, setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>) => void
  onEdit?: (hospital: HospitalUpdateEvent, setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
}
const HospitalForm = (props: HospitalFormProps) => {
  const { hospital, onCancel, onAdd, onEdit, saveTrigger } = props

  const isAdding = typeof onAdd === 'function'
  const isEditing = typeof onEdit === 'function'

  const [tempHospital, setTempHospital] = useState<Partial<Hospital>>(hospital || {})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = () => {
    if (!tempHospital.name) {
      SnackbarContext.show(`Hospital name is required`, 'error')
      return
    }

    setIsSubmitting(true)

    if (!hospital) {
      // format the data to match the API
      const formattedHospital: HospitalAddEvent = {
        name: tempHospital.name,
        permalink: tempHospital.permalink || undefined,
        imageURL: tempHospital.imageURL || undefined,
        latitude: tempHospital.latitude || undefined,
        longitude: tempHospital.longitude || undefined,
        address: tempHospital.address || undefined,
        city: tempHospital.city || undefined,
        state: tempHospital.state || undefined,
        zipcode: tempHospital.zipcode || undefined,
        website: tempHospital.website || undefined,
        supportTelephone: tempHospital.supportTelephone || undefined,
        supportEmail: tempHospital.supportEmail || undefined,
        externalId: tempHospital.externalId || undefined,
        averageRating: tempHospital.averageRating || 0,
      }

      // adding a new hospital
      if (typeof onAdd === 'function') {
        onAdd(formattedHospital, setIsSubmitting)
      }
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof Hospital) => {
        return tempHospital[field] !== undefined && tempHospital[field] !== hospital[field]
      }

      if (!tempHospital.permalink) {
        SnackbarContext.show(`Hospital permalink cannot be blank`, 'error')
        setIsSubmitting(false)
        return
      }

      const changedProperties: HospitalUpdateEvent = {
        id: hospital.id,
        name: didChange('name') ? tempHospital.name : undefined,
        permalink: didChange('permalink') ? tempHospital.permalink : undefined,
        imageURL: didChange('imageURL') && tempHospital.imageURL !== null ? tempHospital.imageURL : undefined,
        latitude: didChange('latitude') && tempHospital.latitude !== null ? tempHospital.latitude : undefined,
        longitude: didChange('longitude') && tempHospital.longitude !== null ? tempHospital.longitude : undefined,
        address: didChange('address') && tempHospital.address !== null ? tempHospital.address : undefined,
        city: didChange('city') && tempHospital.city !== null ? tempHospital.city : undefined,
        state: didChange('state') && tempHospital.state !== null ? tempHospital.state : undefined,
        zipcode: didChange('zipcode') && tempHospital.zipcode !== null ? tempHospital.zipcode : undefined,
        website: didChange('website') && tempHospital.website !== null ? tempHospital.website : undefined,
        supportTelephone:
          didChange('supportTelephone') && tempHospital.supportTelephone !== null
            ? tempHospital.supportTelephone
            : undefined,
        supportEmail:
          didChange('supportEmail') && tempHospital.supportEmail !== null ? tempHospital.supportEmail : undefined,
        externalId: didChange('externalId') && tempHospital.externalId !== null ? tempHospital.externalId : undefined,
        averageRating:
          didChange('averageRating') && tempHospital.averageRating !== null ? tempHospital.averageRating : undefined,
      }

      // edit an existing hospital
      if (typeof onEdit === 'function') {
        onEdit(changedProperties, setIsSubmitting)
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {isSubmitting && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Name</InputLabel>
              <OutlinedInput
                id="hospital-name"
                label="Name"
                value={tempHospital.name || ''}
                required
                onChange={(e) => {
                  setTempHospital((prev) => ({ ...prev, name: e.target.value }))
                }}
                onKeyDown={keyDownSubmit}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Permalink</InputLabel>
              <OutlinedInput
                id="hospital-permalink"
                label="Permalink"
                value={tempHospital.permalink || ''}
                required={isEditing}
                startAdornment={
                  <InputAdornment position="start">{AppConfig.companionAppUrl}/hospitals/</InputAdornment>
                }
                onChange={(e) => {
                  setTempHospital((prev) => ({ ...prev, permalink: generatePermalink(e.target.value) }))
                }}
                onKeyDown={keyDownSubmit}
              />
              <FormHelperText
                sx={{
                  mb: 1, // extra space to compensate for the helper text visually separate from the next item's label
                }}
              >
                This is the unique identifier for this hospital used in the URL for this hospital's page within the app.
                It can only contain letters, numbers, and dashes. Invalid characters will be removed.{' '}
                {isAdding ? 'If you leave this blank, one will be generated for you.' : ''}
              </FormHelperText>
            </FormControl>
            <DetailInput
              label="Image URL"
              field="imageURL"
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Address"
              field="address"
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="City"
              field="city"
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="hospital-state-select-label">State</InputLabel>
              <Select
                labelId="hospital-state-select-label"
                id="hospital-state-select"
                value={tempHospital.state || ''}
                label="State"
                onChange={(e) => {
                  setTempHospital((prev) => ({ ...prev, state: e.target.value as USStates }))
                }}
              >
                {Object.entries(USStates).map((eachState) => {
                  return (
                    <MenuItem key={eachState[0]} value={eachState[1]}>
                      {startCase(camelCase(eachState[0]))}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <DetailInput
              label="Zip Code"
              field="zipcode"
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Latitude"
              field="latitude"
              type="number"
              placeholder="e.g. 42.3601"
              helperText="Up to 15 points of precision are supported  for GPS latitude."
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Longitude"
              field="longitude"
              type="number"
              placeholder="e.g. 42.3601"
              helperText="Up to 15 points of precision are supported  for GPS latitude."
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Website"
              field="website"
              type="url"
              placeholder="e.g. https://www.example.com"
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Support Telephone"
              field="supportTelephone"
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Support Email"
              field="supportEmail"
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="External ID"
              field="externalId"
              tempHospital={tempHospital}
              setTempHospital={setTempHospital}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Average Rating</InputLabel>
              <OutlinedInput
                id="hospital-averageRating"
                label="Average Rating"
                type="number"
                inputProps={{
                  step: 0.5,
                  min: 0,
                  max: 5,
                }}
                value={tempHospital.averageRating || ''}
                onChange={(e) => {
                  setTempHospital((prev) => ({ ...prev, averageRating: Number(e.target.value) }))
                }}
                onKeyDown={keyDownSubmit}
              />
              <FormHelperText>
                You shouldn't need to update this manually since the system updates it after each review but if someone
                went wrong of you wish to temporarily change the rating, this field can be set to a number between 0 - 5
                in 0.5 increments.
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default HospitalForm
