import { useState } from 'react'

import { Hospital } from '../../../models/Hospital.model'
import { HospitalAddEvent } from '../../../events/HospitalAddEvent'
import { HospitalUpdateEvent } from '../../../events/HospitalUpdateEvent'

import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Button, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import HospitalForm from './HospitalForm.component'

type HospitalFormDialogProps = {
  open: boolean
  hospital?: Hospital
  onAdd?: (hospital: HospitalAddEvent, setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>) => void
  onEdit?: (hospital: HospitalUpdateEvent, setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>) => void
  onCancel: () => void
  onClose: () => void
}
export const HospitalFormDialog = (props: HospitalFormDialogProps) => {
  const { hospital, onCancel, onClose, onAdd, onEdit, open } = props

  // used to remotely trigger the save of the child form
  const [saveTrigger, setSaveTrigger] = useState(0)

  const handleSave = () => {
    setSaveTrigger((prev) => prev + 1)
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <Typography sx={{ mr: 2, flex: 1 }} variant="h6" component="div">
            {hospital ? 'Edit' : 'Add'} Hospital
          </Typography>
          <Button autoFocus color="inherit" onClick={handleSave}>
            Save
          </Button>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <HospitalForm hospital={hospital} onAdd={onAdd} onEdit={onEdit} onCancel={onCancel} saveTrigger={saveTrigger} />
      </DialogContent>
    </Dialog>
  )
}
export default HospitalFormDialog
