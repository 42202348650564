import { useEffect, useState } from 'react'
import { generatePermalink } from '../../../utils/formatting'
import { AppConfig } from '../../App.config'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { Agency } from '../../../models/Agency.model'
import { AgencyAddEvent } from '../../../events/AgencyAddEvent'
import { AgencyUpdateEvent } from '../../../events/AgencyUpdateEvent'

import { Stack, FormControl, InputLabel, OutlinedInput, InputAdornment, FormHelperText, Button } from '@mui/material'
import Loading from '../../../components/Loading/Loading.component'

type DetailInputProps = {
  label: string
  field: keyof Agency
  type?: 'text' | 'number' | 'email' | 'url'
  required?: boolean
  placeholder?: string
  helperText?: string
  tempAgency: Partial<Agency>
  setTempAgency: React.Dispatch<React.SetStateAction<Partial<Agency>>>
  keyDownSubmit: (e: React.KeyboardEvent) => void
}
const DetailInput = (props: DetailInputProps) => {
  const {
    label,
    field,
    required = false,
    helperText,
    placeholder,
    type = 'text',
    tempAgency,
    setTempAgency,
    keyDownSubmit,
  } = props

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        id={`agency-${field}`}
        label={label}
        value={tempAgency[field] || ''}
        type={type}
        placeholder={placeholder}
        required={required}
        onChange={(e) => {
          setTempAgency((prev) => ({ ...prev, [field]: e.target.value }))
        }}
        onKeyDown={keyDownSubmit}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export type AgencyFormProps = {
  agency?: Agency
  onAdd?: (agency: AgencyAddEvent, setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>) => void
  onEdit?: (agency: AgencyUpdateEvent, setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>) => void
  onCancel: () => void
  // used to remotely trigger the save action
  saveTrigger?: number
}
const AgencyForm = (props: AgencyFormProps) => {
  const { agency, onCancel, onAdd, onEdit, saveTrigger } = props

  const isAdding = typeof onAdd === 'function'
  const isEditing = typeof onEdit === 'function'

  const [tempAgency, setTempAgency] = useState<Partial<Agency>>(agency || {})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = () => {
    if (!tempAgency.name) {
      SnackbarContext.show(`Agency name is required`, 'error')
      return
    }

    setIsSubmitting(true)

    if (!agency) {
      // format the data to match the API
      const formattedAgency: AgencyAddEvent = {
        name: tempAgency.name,
        permalink: tempAgency.permalink || undefined,
        imageURL: tempAgency.imageURL || undefined,
        address: tempAgency.address || undefined,
        website: tempAgency.website || undefined,
        phone: tempAgency.phone || undefined,
        email: tempAgency.email || undefined,
        facebook: tempAgency.facebook || undefined,
        externalId: tempAgency.externalId || undefined,
        averageRating: tempAgency.averageRating || 0,
      }

      // adding a new agency
      if (typeof onAdd === 'function') {
        onAdd(formattedAgency, setIsSubmitting)
      }
    } else {
      // identify the properties that have changed
      const didChange = (field: keyof Agency) => {
        return tempAgency[field] !== undefined && tempAgency[field] !== agency[field]
      }

      if (!tempAgency.permalink) {
        SnackbarContext.show(`Agency permalink cannot be blank`, 'error')
        setIsSubmitting(false)
        return
      }

      const changedProperties: AgencyUpdateEvent = {
        id: agency.id,
        name: didChange('name') ? tempAgency.name : undefined,
        permalink: didChange('permalink') ? tempAgency.permalink : undefined,
        imageURL: didChange('imageURL') && tempAgency.imageURL !== null ? tempAgency.imageURL : undefined,
        address: didChange('address') && tempAgency.address !== null ? tempAgency.address : undefined,
        website: didChange('website') && tempAgency.website !== null ? tempAgency.website : undefined,
        phone: didChange('phone') && tempAgency.phone !== null ? tempAgency.phone : undefined,
        email: didChange('email') && tempAgency.email !== null ? tempAgency.email : undefined,
        facebook: didChange('facebook') && tempAgency.facebook !== null ? tempAgency.facebook : undefined,
        externalId: didChange('externalId') && tempAgency.externalId !== null ? tempAgency.externalId : undefined,
        averageRating:
          didChange('averageRating') && tempAgency.averageRating !== null ? tempAgency.averageRating : undefined,
      }

      // edit an existing agency
      if (typeof onEdit === 'function') {
        onEdit(changedProperties, setIsSubmitting)
      }
    }
  }

  useEffect(() => {
    if (saveTrigger) {
      // if outside consumer triggered save via a state change, then submit
      handleSubmit()
    }
  }, [saveTrigger])

  const handleCancel = () => {
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }

  const keyDownSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {isSubmitting && <Loading sx={{ py: 20 }} />}
      {!isSubmitting && (
        <>
          <Stack spacing={2} sx={{ my: 2 }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Name</InputLabel>
              <OutlinedInput
                id="agency-name"
                label="Name"
                value={tempAgency.name || ''}
                required
                onChange={(e) => {
                  setTempAgency((prev) => ({ ...prev, name: e.target.value }))
                }}
                onKeyDown={keyDownSubmit}
              />
            </FormControl>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Permalink</InputLabel>
              <OutlinedInput
                id="agency-permalink"
                label="Permalink"
                value={tempAgency.permalink || ''}
                required={isEditing}
                startAdornment={<InputAdornment position="start">{AppConfig.companionAppUrl}/agencies/</InputAdornment>}
                onChange={(e) => {
                  setTempAgency((prev) => ({ ...prev, permalink: generatePermalink(e.target.value) }))
                }}
                onKeyDown={keyDownSubmit}
              />
              <FormHelperText
                sx={{
                  mb: 1, // extra space to compensate for the helper text visually separate from the next item's label
                }}
              >
                This is the unique identifier for this agency used in the URL for this agency's page within the app. It
                can only contain letters, numbers, and dashes. Invalid characters will be removed.{' '}
                {isAdding ? 'If you leave this blank, one will be generated for you.' : ''}
              </FormHelperText>
            </FormControl>
            <DetailInput
              label="Image URL"
              field="imageURL"
              tempAgency={tempAgency}
              setTempAgency={setTempAgency}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Address"
              field="address"
              tempAgency={tempAgency}
              setTempAgency={setTempAgency}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Website"
              field="website"
              type="url"
              placeholder="e.g. https://www.example.com"
              tempAgency={tempAgency}
              setTempAgency={setTempAgency}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Phone"
              field="phone"
              tempAgency={tempAgency}
              setTempAgency={setTempAgency}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Email"
              field="email"
              tempAgency={tempAgency}
              setTempAgency={setTempAgency}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="Facebook"
              field="facebook"
              tempAgency={tempAgency}
              setTempAgency={setTempAgency}
              keyDownSubmit={keyDownSubmit}
            />
            <DetailInput
              label="External ID"
              field="externalId"
              tempAgency={tempAgency}
              setTempAgency={setTempAgency}
              keyDownSubmit={keyDownSubmit}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Average Rating</InputLabel>
              <OutlinedInput
                id="agency-averageRating"
                label="Average Rating"
                type="number"
                inputProps={{
                  step: 0.5,
                  min: 0,
                  max: 5,
                }}
                value={tempAgency.averageRating || ''}
                onChange={(e) => {
                  setTempAgency((prev) => ({ ...prev, averageRating: Number(e.target.value) }))
                }}
                onKeyDown={keyDownSubmit}
              />
              <FormHelperText>
                You shouldn't need to update this manually since the system updates it after each review but if someone
                went wrong of you wish to temporarily change the rating, this field can be set to a number between 0 - 5
                in 0.5 increments.
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Stack>
        </>
      )}
    </>
  )
}
export default AgencyForm
