import { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import {
  Box,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Drawer as MuiDrawer,
  styled,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  Container,
  Avatar,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import AppNavigation from '../components/AppNavigation/AppNavigation.component'
import UserService from '../services/UserService/UserService'

type AppLayoutContextType = {
  setPageName: (newPageName?: string) => void
}
export const AppLayoutContext: AppLayoutContextType = {
  setPageName: () => {
    // if used outside of AppLayout, this function will not have been replaced so throw error
    throw new Error('AppLayoutContext must be used within an AppLayout')
  },
}

const drawerWidth = 240

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  [theme.breakpoints.up('sm')]: {
    // on desktop we want the app bar to live next to the drawer but on mobile it is unaffected by drawer open/close
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      // if the drawer is open, we want to push the app bar to the right
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  },
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    // on mobile we want the drawer to be full screen separate from content
    position: 'fixed',
    width: '100vw',
    // on desktop we want the drawer to push the content to the right
    [theme.breakpoints.up('sm')]: {
      position: 'relative',
      width: drawerWidth,
      ...(!open && {
        // on desktop when drawer is closed, it will be skinny just showing icons
        width: theme.spacing(9),
      }),
    },
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      // on mobile when the drawer is closed, we want it to be hidden
      // this applies to mobile only since desktop overrides in the above media query
      width: 0,
    }),
  },
}))

const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const { user, isAuthenticated } = useAuth0()
  const formattedUser = user ? UserService.formatUser(user) : undefined
  const [pageName, setPageName] = useState('')
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  AppLayoutContext.setPageName = (newPageName = '') => {
    setPageName(newPageName)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      {isAuthenticated && formattedUser && (
        <>
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: '24px', // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                {pageName}
              </Typography>
              <Box>
                {formattedUser.picture ? (
                  <Avatar alt={formattedUser.name} src={formattedUser.picture} />
                ) : (
                  <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
                    {formattedUser.name ? formattedUser.name[0] : ''}
                  </Avatar>
                )}
              </Box>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer} color="inherit">
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <AppNavigation />
          </Drawer>
        </>
      )}
      <Box
        component="main"
        sx={{
          background: (theme) => theme.palette.grey[200],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          {children}
        </Container>
      </Box>
    </Box>
  )
}
export default AppLayout
