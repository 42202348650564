export function removeTrailingSlash(string: string) {
  return string.replace(/\/$/, '')
}

export const getObjectKeyFromValue = (object: Record<string, unknown>, value: unknown): string | undefined => {
  return Object.keys(object)[Object.values(object).indexOf(value)]
}

export const generatePermalink = (name: string): string => {
  const lowercaseName = (name || '').toLowerCase()
  // take the name and remove all non-alphanumeric characters
  const nameWithoutNonAlphaNumeric = lowercaseName.replace(/[^a-z0-9-\s]/gi, '')
  // replace all spaces with dashes
  const nameWithDashes = nameWithoutNonAlphaNumeric.replace(/ /g, '-')
  // remove any consecutive dashes eg. --- => -
  const nameWithDashesAndNoConsecutiveDashes = nameWithDashes.replace(/-+/g, '-')

  return nameWithDashesAndNoConsecutiveDashes
}
