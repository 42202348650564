import { createSvgIcon, SvgIconProps } from '@mui/material'

const HospitalIconRaw = createSvgIcon(
  <>
    <path d="m1049.38,977.87c-9.33-4.49-20.33-6.69-30.76-7.48-16.04-1.22-32.24-.32-49.07-.32v-11.96c0-262.18.12-524.37-.27-786.55-.03-17.07-1.54-34.85-6.4-51.09C941.38,48.55,880.99,3.72,806.11,3.59c-177.32-.31-354.64-.34-531.96.04-56.35.12-101.85,23.3-134.71,69.41-20.71,29.05-28.94,62-28.91,97.62.21,262.44.11,524.89.11,787.33v12.06c-7.4,0-13.66-.12-19.92.02-14.12.32-28.37-.33-42.33,1.37-34.66,4.22-55.34,43.68-40.05,75.08,11.22,23.03,30.7,31.47,55.12,31.47,317.71-.02,635.42-.01,953.13-.06,4.69,0,9.4-.47,14.06-1.06,22.31-2.82,40.79-20.07,45.23-42.06,4.6-22.75-5.85-47-26.51-56.95Zm-455.93-8.45h-106.3v-213.12h106.3v213.12Zm107.75,0v-10.07c0-66.27-.32-132.55.12-198.81.25-37.09-13.12-67.31-42.16-90.38-15.98-12.7-34.31-21.11-54.66-21.42-44.49-.68-89.15-2.06-133.46.95-48.8,3.32-89.86,48.4-91.32,97.5-1.41,47.63-.6,95.33-.71,143-.06,26.18-.01,52.36-.01,79.46h-161.09v-8.65c0-264.04,0-528.08,0-792.12,0-35.23,21.97-57.43,57.34-57.44,176.55-.08,353.1-.08,529.65,0,35.35.02,57.38,22.23,57.38,57.44.02,264.04,0,528.08,0,792.12,0,2.6,0,5.19,0,8.42h-161.09Z" />
    <path d="m673.94,541.92c-14.25-1.28-28.92-.82-42.64-4.23-23.29-5.78-39.55-30.75-37.33-53.59,2.66-27.32,21.78-48.29,47.73-49.82,21.63-1.27,43.45-1.29,65.07-.02,27.27,1.6,48.95,26.74,48.06,53.56-.95,28.64-22.97,51.58-50.97,52.73-9.92.41-19.87.07-29.81.07-.03.43-.07.87-.1,1.3Z" />
    <path d="m405.95,325.67c-8.64,0-17.28-.28-25.9.05-28.1,1.1-54.12-21.94-54.69-53-.54-29.09,22.51-52.82,52.89-53.98,18.29-.7,36.65-.65,54.94-.02,29.73,1.03,49.64,20.45,52.96,50.65,2.64,23.99-15.14,48.86-41.17,54.6-12.56,2.77-25.97,1.68-39,2.33,0-.21-.02-.42-.03-.63Z" />
    <path d="m674.62,325.6c-9.42,0-18.85.22-28.26-.04-24.95-.71-46.29-18.21-51.23-41.76-5.17-24.6,6.61-49.49,29.53-59.81,7.4-3.33,16.09-4.83,24.28-5.2,16.97-.77,34.01-.52,51.02-.18,43,.87,68.6,44.22,47.33,80.54-9.4,16.05-23.74,25.55-42.84,26.28-9.93.38-19.88.07-29.82.07,0,.04,0,.07,0,.11Z" />
    <path d="m405.46,541.93c-14.25-1.28-28.93-.82-42.64-4.24-23.46-5.84-39.52-30.56-37.23-53.53,2.75-27.54,21.63-48.33,47.57-49.86,21.63-1.28,43.45-1.3,65.07-.03,27.23,1.6,48.89,26.67,48.14,53.53-.8,28.4-23.07,51.6-51.01,52.75-9.92.41-19.87.07-29.81.07-.03.44-.07.87-.1,1.31Z" />
  </>,
  'Hospital',
)

const HospitalIcon = (props: SvgIconProps) => {
  return <HospitalIconRaw {...props} viewBox="0 0 1080 1080" />
}

export default HospitalIcon
