import axios, { CancelTokenSource } from 'axios'

import { PaginatedResponse } from '../../models/PaginatedResponse.model'
import { Hospital } from '../../models/Hospital.model'
import { HospitalDTO } from '../../models/HospitalDTO.model'
import { USStates } from '../../models/USStates.model'
import { HospitalAddEvent } from '../../events/HospitalAddEvent'
import { HospitalUpdateEvent } from '../../events/HospitalUpdateEvent'

import ScrubstrApiQueryService, {
  ScrubstrApiQueryServiceGetEvent,
} from '../ScrubstrApiQueryService/ScrubstrApiQueryService'

export class HospitalService {
  private autocompleteHospitalsSource: CancelTokenSource | null = null

  /**
   * @description Search for a single hospital by id or permalink
   *
   * @param id
   * @param permalink
   *
   * @returns promise for a Hospital
   */
  public async searchOne({ id, permalink }: { id?: number; permalink?: string }): Promise<Hospital> {
    const params = {
      id,
      permalink,
      excludeReviews: 'true', // always excluding reviews for the admin interface
    }
    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: `hospital/search-one`,
      params,
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const hospitalRaw = await scrubstrApiQueryService.get<HospitalDTO>(scrubstrApiQueryRequest)
      return this.formatHospital(hospitalRaw)
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }
  }

  public async search({
    page,
    pageSize,
    term,
    state,
    orderBy,
    orderDirection,
    autocomplete,
  }: {
    page?: number
    pageSize?: number
    term: string
    state?: USStates
    orderBy?: keyof Hospital
    orderDirection?: 'asc' | 'desc'
    autocomplete?: boolean
  }): Promise<{
    hospitals: Hospital[]
    totalResults: number
    totalPages: number
    currentPage: number
  }> {
    const params = {
      page,
      pageSize,
      orderBy,
      orderDirection: orderDirection?.toUpperCase(),
      name: term || undefined,
      state,
      excludeReviews: 'true', // always excluding reviews for the admin interface
    }

    const scrubstrApiQueryRequest: ScrubstrApiQueryServiceGetEvent = {
      endpoint: 'hospital',
      params,
    }

    if (autocomplete) {
      if (this.autocompleteHospitalsSource !== null) {
        this.autocompleteHospitalsSource.cancel('cancelToken triggered')
      }
      this.autocompleteHospitalsSource = axios.CancelToken.source()
      scrubstrApiQueryRequest.cancelToken = this.autocompleteHospitalsSource.token
    }

    const hospitalResults: {
      hospitals: Hospital[]
      totalResults: number
      totalPages: number
      currentPage: number
    } = {
      hospitals: [],
      totalResults: 0,
      totalPages: 0,
      currentPage: Number(page),
    }
    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const hospitalsRaw = await scrubstrApiQueryService.get<PaginatedResponse<HospitalDTO>>(scrubstrApiQueryRequest)
      // wrap to avoid errors if the request is cancelled
      if (hospitalsRaw) {
        hospitalResults.hospitals = hospitalsRaw.data.map((hospital) => this.formatHospital(hospital))

        hospitalResults.totalResults = hospitalsRaw.total
        hospitalResults.totalPages = Math.ceil(hospitalsRaw.total / 12)
      }
    } catch (error) {
      console.error(error)
      throw 'Sorry, there was a problem with your request.'
    }

    return hospitalResults
  }

  public async add(hospital: HospitalAddEvent): Promise<Hospital> {
    const scrubstrApiQueryRequest = {
      endpoint: 'hospital',
      data: hospital,
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const hospitalRaw = await scrubstrApiQueryService.post<HospitalDTO>(scrubstrApiQueryRequest)
      return this.formatHospital(hospitalRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async update(id: number, hospital: HospitalUpdateEvent): Promise<Hospital> {
    const scrubstrApiQueryRequest = {
      endpoint: `hospital/${id}`,
      data: hospital,
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      const hospitalRaw = await scrubstrApiQueryService.patch<HospitalDTO>(scrubstrApiQueryRequest)
      return this.formatHospital(hospitalRaw)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async delete(id: number): Promise<void> {
    const scrubstrApiQueryRequest = {
      endpoint: `hospital/${id}`,
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      await scrubstrApiQueryService.delete(scrubstrApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public async import(
    hospitals: Partial<Hospital>[],
    options: {
      matchingField?: string | undefined
      deleteUnmatchedExisting: boolean
    },
  ): Promise<void> {
    const data = hospitals.map((hospital) => {
      return {
        ...hospital,
        id: hospital.id ? Number(hospital.id) : undefined,
        latitude: hospital.latitude ? Number(hospital.latitude) : undefined,
        longitude: hospital.longitude ? Number(hospital.longitude) : undefined,
        averageRating: hospital.averageRating ? Number(hospital.averageRating) : undefined,
      }
    })
    const scrubstrApiQueryRequest = {
      endpoint: `hospital/sync`,
      data: {
        data,
        matchingProp: options.matchingField,
        deleteMissing: options.deleteUnmatchedExisting,
      },
    }

    try {
      const scrubstrApiQueryService = new ScrubstrApiQueryService()
      await scrubstrApiQueryService.post(scrubstrApiQueryRequest)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        throw error.message
      } else if ((error as { data: { message: string } })?.data?.message) {
        throw (error as { data: { message: string } })?.data?.message
      }
      throw error
    }
  }

  public formatHospital(hospital: HospitalDTO): Hospital {
    return {
      id: hospital.id,
      name: hospital.name,
      imageURL: hospital.imageURL,
      permalink: hospital.permalink,
      latitude: hospital.latitude,
      longitude: hospital.longitude,
      address: hospital.address,
      city: hospital.city,
      state: hospital.state,
      zipcode: hospital.zipcode,
      website: hospital.website,
      supportTelephone: hospital.supportTelephone,
      supportEmail: hospital.supportEmail,
      externalId: hospital.externalId,
      averageRating: hospital.averageRating,
      updatedAt: hospital.updatedAt,
      createdAt: hospital.createdAt,
    }
  }
}
