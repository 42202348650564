import { useLocation } from 'react-router-dom'

import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import HomeIcon from '@mui/icons-material/Home'
import HospitalIcon from '../../icons/HospitalIcon'
import AgencyIcon from '../../icons/AgencyIcon'

const AppNavigation = () => {
  const location = useLocation()

  return (
    <List
      component="nav"
      sx={(theme) => ({
        '& .MuiListItemIcon-root': {
          color: 'inherit',
        },
        '& .MuiListItemButton-root.Mui-selected': {
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          ':hover': {
            backgroundColor: theme.palette.secondary.main,
          },
        },
      })}
    >
      <ListItem disablePadding>
        <ListItemButton component="a" href="/" selected={location.pathname === '/'}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component="a" href="/hospitals" selected={location.pathname.startsWith('/hospitals')}>
          <ListItemIcon>
            <HospitalIcon />
          </ListItemIcon>
          <ListItemText primary="Hospitals" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component="a" href="/agencies" selected={location.pathname.startsWith('/agencies')}>
          <ListItemIcon>
            <AgencyIcon />
          </ListItemIcon>
          <ListItemText primary="Agencies" />
        </ListItemButton>
      </ListItem>
      <Divider sx={{ my: 1 }} />
      <ListItem disablePadding>
        <ListItemButton component="a" href="/logout">
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </ListItem>
    </List>
  )
}
export default AppNavigation
