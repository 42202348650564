import { useEffect, useMemo, useRef, useState } from 'react'
import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'
import { getObjectKeyFromValue } from '../../../utils/formatting'
import * as d3 from 'd3'

import { AppLayoutContext } from '../../../providers/AppLayout'
import { useUrlStateParams } from '../../../utils/url'
import { SnackbarContext } from '../../../providers/SnackbarContext'

import { Hospital, hospitalFields } from '../../../models/Hospital.model'
import { USStates } from '../../../models/USStates.model'
import { HospitalAddEvent } from '../../../events/HospitalAddEvent'
import { HospitalUpdateEvent } from '../../../events/HospitalUpdateEvent'

import { HospitalService } from '../../../services/HospitalService/HospitalService'

import {
  Box,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Paper,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DataTable from '../../../components/DataTable/DataTable.component'
import Loading from '../../../components/Loading/Loading.component'
import ImportExportDialog, {
  ImportExportDialogProps,
} from '../../../components/ImportExportDialog/ImportExportDialog.component'
import Link from '@mui/material/Link'
import HospitalFormDialog from '../shared/HospitalFormDialog.component'

const HospitalSearch = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [searchError, setSearchError] = useState('')
  const [rowActionsAnchorEl, setRowActionsAnchorEl] = useState<null | HTMLElement>(null)
  const isActionsMenuOpen = Boolean(rowActionsAnchorEl)
  const [selectedRow, setSelectedRow] = useState<Hospital | undefined>()
  const [isAdding, setIsAdding] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const [term, setTerm] = useUrlStateParams(
    '',
    'term',
    (value) => encodeURIComponent(value.toString()),
    (value) => (value ? decodeURIComponent(value) : ''),
  )
  const termInputRef = useRef<HTMLInputElement>()
  const [state, setState] = useUrlStateParams<USStates | undefined>(
    undefined,
    'state',
    (value) => (value ? encodeURIComponent(value.toString()) : ''),
    (value) => {
      // confirm the value provided is a valid state enum value
      const stateOptions = Object.values(USStates)
      return stateOptions.includes(value as USStates) ? (value as USStates) : undefined
    },
  )
  const [tempState, setTempState] = useState<USStates | undefined>(state)
  const [orderBy, setOrderBy] = useState<keyof Hospital | undefined>('name')
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc' | undefined>('asc')
  const [page, setPage] = useUrlStateParams(
    1,
    'page',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 1),
  )
  const [pageSize, setPageSize] = useUrlStateParams(
    12,
    'page-size',
    (value) => value.toString(),
    (value) => (!isNaN(Number(value)) ? Number(value) : 12),
  )

  const [hospitals, setHospitals] = useState<Hospital[]>([])
  const [totalResults, setTotalResults] = useState(0)

  const [isImportExportOpen, setIsImportExportOpen] = useState(false)

  const hospitalService = useMemo(() => new HospitalService(), []) // memo to prevent re-creating service on every render

  useEffect(() => {
    AppLayoutContext.setPageName('Hospitals')
  }, [])

  useEffect(() => {
    setIsLoading(true)
    setSearchError('')
    hospitalService
      .search({ page, pageSize, term, state: state || undefined, orderBy, orderDirection })
      .then((results) => {
        setIsLoading(false)
        setHospitals(results.hospitals)
        setTotalResults(results.totalResults)
        setPage(results.currentPage)
      })
      .catch((err) => {
        setSearchError(err)
        console.error(err)
        setIsLoading(false)
      })
  }, [state, term, page, pageSize, orderBy, orderDirection])

  const handleRowActionsClick = (event: React.MouseEvent<HTMLButtonElement>, hospital: Hospital) => {
    setRowActionsAnchorEl(event.currentTarget)
    setSelectedRow(hospital)
  }
  const handleRowActionsClose = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
  }

  const columns = useMemo(() => {
    const columns: GridColDef<Hospital>[] = [
      {
        field: 'actions',
        headerName: '',
        width: 60,
        sortable: false,
        renderCell: (params) => (
          <>
            <IconButton
              id={`row-actions-button-${params.row.id}`}
              color="primary"
              aria-label="row actions"
              aria-controls={selectedRow?.id === params.row.id ? 'row-actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={selectedRow?.id === params.row.id ? 'true' : undefined}
              onClick={(event) => handleRowActionsClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </>
        ),
      },
      {
        field: 'id',
        headerName: 'ID',
        width: 100,
        renderCell: (params) => (
          <Link color="primary" aria-label="hospital detail" href={`/hospitals/${params.row.id}`}>
            {params.row.id}
          </Link>
        ),
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 400,
        renderCell: (params) => (
          <Link color="primary" aria-label="hospital detail" href={`/hospitals/${params.row.id}`}>
            {params.row.name}
          </Link>
        ),
      },
      { field: 'city', headerName: 'City', width: 200 },
      {
        field: 'state',
        headerName: 'State',
        width: 100,
        valueFormatter: (params) => getObjectKeyFromValue(USStates, params.value as USStates),
      },
      { field: 'averageRating', headerName: 'Average Rating', width: 200 },
    ]
    return columns
  }, [])

  const handleSubmit = () => {
    setPage(1)
    setTerm(termInputRef?.current?.value || '')
    setState(tempState)
  }

  const handleAddClick = () => {
    setIsAdding(true)
  }

  const handleAddCancel = () => {
    setIsAdding(false)
  }

  const handleAddSubmit = (
    newHospital: HospitalAddEvent,
    setIsAddFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (!Object.keys(newHospital).length) {
      // nothing changed so just close the dialog
      setIsAdding(false)
    } else {
      setIsLoading(true)
      hospitalService
        .add(newHospital)
        .then(() => {
          SnackbarContext.show('Hospital added successfully!')

          setIsAdding(false)

          // fetch new data
          setSearchError('')
          hospitalService
            .search({ pageSize, term, state: state || undefined, orderBy, orderDirection })
            .then((results) => {
              setHospitals(results.hospitals)
              setTotalResults(results.totalResults)
              setPage(1)
              setIsLoading(false)
            })
            .catch((err) => {
              setSearchError(err)
              console.error(err)
              setIsLoading(false)
            })
        })
        .catch((err) => {
          SnackbarContext.show(`Hospital failed to add: ${err?.data?.message || err?.message}`, 'error')
          console.error(err)
          setIsLoading(false)
          setIsAddFormSubmitting(false)
        })
    }
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleEditCancel = () => {
    setRowActionsAnchorEl(null)
    setSelectedRow(undefined)
    setIsEditing(false)
  }

  const handleEditSubmit = (
    changedProperties: HospitalUpdateEvent,
    setIsEditFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (!Object.keys(changedProperties).length) {
      // nothing changed so just close the dialog
      setRowActionsAnchorEl(null)
      setSelectedRow(undefined)
      setIsEditing(false)
    } else if (selectedRow) {
      setIsLoading(true)
      hospitalService
        .update(Number(selectedRow.id), changedProperties)
        .then((result) => {
          SnackbarContext.show('Hospital updated successfully!')
          setRowActionsAnchorEl(null)
          setSelectedRow(undefined)
          // replace the hospital in the list with the updated one
          setHospitals((prevHospitals) => {
            const index = prevHospitals.findIndex((hospital) => hospital.id === result.id)
            if (index > -1) {
              const newHospitals = [...prevHospitals]
              newHospitals[index] = result
              return newHospitals
            }
            return prevHospitals
          })
          setIsLoading(false)
          setIsEditing(false)
        })
        .catch((err) => {
          SnackbarContext.show(`Hospital failed to edit: ${err?.data?.message || err?.message}`, 'error')
          console.error(err)
          setIsLoading(false)
          setIsEditFormSubmitting(false)
        })
    }
  }

  const handleDeleteClick = () => {
    // show a confirmation dialog before deleting
    setIsDeleteConfirmationOpen(true)
  }

  const handleDeleteConfirmationClose = () => {
    setIsDeleteConfirmationOpen(false)
    handleRowActionsClose()
  }

  const handleDelete = () => {
    if (selectedRow?.id) {
      setIsLoading(true)
      setIsDeleteConfirmationOpen(false)
      hospitalService
        .delete(selectedRow.id)
        .then(() => {
          SnackbarContext.show('Hospital deleted successfully!')
          setRowActionsAnchorEl(null)
          setSelectedRow(undefined)
          setHospitals(hospitals.filter((hospital) => hospital.id !== selectedRow?.id))
          setTotalResults(totalResults - 1)
          setIsLoading(false)
        })
        .catch((err) => {
          console.error(err)
          SnackbarContext.show(`Hospital failed to delete: ${err?.data?.message || err?.message}`, 'error')
          setIsLoading(false)
        })
    }
  }

  const openImportExport = () => {
    setIsImportExportOpen(true)
  }

  const handleImportExportClose = () => {
    setIsImportExportOpen(false)
  }

  const handleImport: ImportExportDialogProps['onImport'] = (data: Partial<Hospital>[], options) => {
    setIsImportExportOpen(false)
    if (!data) {
      SnackbarContext.show('Failed to read/parse file', 'error')
      console.error('Failed to read/parse file')
    } else {
      setIsLoading(true)
      hospitalService
        .import(data, options)
        .then(() => {
          SnackbarContext.show('Hospitals imported successfully!')

          // fetch new data
          setSearchError('')
          hospitalService
            .search({ pageSize, term, state: state || undefined, orderBy, orderDirection })
            .then((results) => {
              setHospitals(results.hospitals)
              setTotalResults(results.totalResults)
              setPage(1)
              setIsLoading(false)
            })
            .catch((err) => {
              setSearchError(err)
              console.error(err)
              setIsLoading(false)
            })
        })
        .catch((err) => {
          SnackbarContext.show(`Hospital failed to import: ${err?.data?.message || err?.message}`, 'error')
          console.error(err)
          setIsLoading(false)
        })
    }
  }

  const handleExport = () => {
    // do a request to the API to get the data to export with no pagination
    setIsLoading(true)
    setIsImportExportOpen(false)
    hospitalService
      .search({ pageSize: 0, term, state: state || undefined, orderBy, orderDirection })
      .then((results) => {
        const csvData = d3.csvFormat(results.hospitals)
        // initiate a download of the csv file
        const blob = new Blob([csvData], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.setAttribute('href', url)
        link.setAttribute('download', 'hospitals.csv')
        link.click()
        setIsLoading(false)
      })
      .catch((err) => {
        SnackbarContext.show(
          `Hospital failed to fetch hospitals for export: ${err?.data?.message || err?.message}`,
          'error',
        )
        console.error(err)
      })
  }

  return (
    <>
      {selectedRow && (
        <HospitalFormDialog
          open={isEditing}
          hospital={selectedRow}
          onEdit={handleEditSubmit}
          onCancel={handleEditCancel}
          onClose={handleEditCancel}
        />
      )}
      <HospitalFormDialog
        open={isAdding}
        onAdd={handleAddSubmit}
        onCancel={handleAddCancel}
        onClose={handleAddCancel}
      />
      <Dialog fullWidth maxWidth="xs" open={isDeleteConfirmationOpen} onClose={handleDeleteConfirmationClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent dividers>
          Are you sure you want to delete hospital id {selectedRow?.id}: {selectedRow?.name}?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDeleteConfirmationClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <ImportExportDialog
        open={isImportExportOpen}
        exampleImportFileUrl="/example-import-files/hospitals.csv"
        importFields={hospitalFields.filter((field) => !['updatedAt', 'createdAt'].includes(field))}
        onImport={handleImport}
        onExport={handleExport}
        onClose={handleImportExportClose}
      />
      <Paper sx={{ p: 2 }}>
        {isLoading && !searchError && <Loading sx={{ py: 20 }} />}
        {searchError && (
          <Stack direction="row" justifyContent="center" alignItems="center" sx={{ py: 20 }}>
            <Typography variant="error">{searchError}</Typography>
          </Stack>
        )}
        {!isLoading && !searchError && (
          <>
            <Box sx={{ pt: 1, mb: 1 }}>
              <Stack direction={{ zero: 'column', md: 'row' }} spacing={1}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel>Hospital Name</InputLabel>
                  <OutlinedInput
                    id="search-term"
                    label="Hospital Name"
                    defaultValue={term || ''}
                    inputRef={termInputRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit()
                      }
                    }}
                    endAdornment={
                      termInputRef?.current?.value ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="clear search term"
                            onClick={() => {
                              if (termInputRef?.current?.value) {
                                termInputRef.current.value = ''
                              }
                              setPage(1)
                              setTerm('')
                            }}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ) : undefined
                    }
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={(theme) => ({ [theme.breakpoints.up('md')]: { width: 500 }, maxWidth: '100%' })}
                >
                  <InputLabel id="state-select-label">State</InputLabel>
                  <Select
                    labelId="state-select-label"
                    id="state-select"
                    value={tempState || ''}
                    label="State"
                    onChange={(e) => {
                      setTempState(e.target.value as USStates)
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Object.entries(USStates).map((eachState) => {
                      return (
                        <MenuItem key={eachState[0]} value={eachState[1]}>
                          {startCase(camelCase(eachState[0]))}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={(theme) => ({ [theme.breakpoints.up('md')]: { width: 150 }, maxWidth: '100%' })}
                >
                  Search
                </Button>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mt: 1 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton color="primary" aria-label="add new" onClick={handleAddClick}>
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="show import export"
                    onClick={() => {
                      openImportExport()
                    }}
                  >
                    <ImportExportIcon />
                  </IconButton>
                </Stack>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    if (termInputRef?.current?.value) {
                      termInputRef.current.value = ''
                    }
                    setTempState(undefined)
                    setPage(1)
                    setTerm('')
                    setState(undefined)
                  }}
                >
                  Clear
                </Button>
              </Stack>
            </Box>

            {hospitals.length > 0 ? (
              <>
                <Menu
                  id="row-actions-menu"
                  anchorEl={rowActionsAnchorEl}
                  open={isActionsMenuOpen}
                  onClose={handleRowActionsClose}
                  MenuListProps={{
                    'aria-labelledby': `row-actions-button${selectedRow?.id}`,
                  }}
                >
                  <MenuItem href={`/hospitals/${selectedRow?.id}`} component={Link}>
                    View
                  </MenuItem>
                  <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                  <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                </Menu>
                <DataTable
                  columns={columns}
                  data={hospitals}
                  total={totalResults}
                  paginationMode="server"
                  page={page}
                  pageSize={pageSize}
                  onPageChange={(newPage) => {
                    setPage(newPage)
                  }}
                  onPageSizeChange={(newPageSize) => {
                    setPageSize(newPageSize)
                  }}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(newOrder) => {
                    setOrderBy(newOrder.field ? (newOrder.field as keyof Hospital) : undefined)
                    setOrderDirection(newOrder.sort)
                  }}
                />
              </>
            ) : (
              <Box sx={{ p: 2 }}>
                <Typography>No results found</Typography>
              </Box>
            )}
          </>
        )}
      </Paper>
    </>
  )
}
export default HospitalSearch
