export type Agency = {
  id: number
  name: string
  imageURL: string | null
  permalink: string
  address: string | null
  website: string | null
  phone: string | null
  email: string | null
  facebook: string | null
  externalId: string | null
  averageRating: number
  updatedAt: string
  createdAt: string
}

// we offer this to enable the enumerate the Agency type's fields in js/ts files
export const agencyFields: (keyof Agency)[] = [
  'id',
  'name',
  'imageURL',
  'permalink',
  'address',
  'website',
  'phone',
  'email',
  'facebook',
  'externalId',
  'averageRating',
  'updatedAt',
  'createdAt',
]
