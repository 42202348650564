import { useEffect, useState } from 'react'

import { Pagination, Stack, SxProps, TablePagination } from '@mui/material'
import Box from '@mui/material/Box/Box'
import { DataGrid, GridColDef, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid'

type CustomPaginationProps = {
  total?: number
  page?: number
  pageSize?: number
  pageSizeOptions?: number[]
}
function CustomPagination(props: CustomPaginationProps) {
  const { total = 0, page = 1, pageSizeOptions = [12, 24, 48] } = props
  const pageSize = props.pageSize || pageSizeOptions[0]
  const apiRef = useGridApiContext()

  return (
    <Stack
      direction={{ sm: 'column', md: 'row' }}
      justifyContent="end"
      alignItems="center"
      spacing={2}
      sx={(theme) => ({
        [theme.breakpoints.down('md')]: {
          mb: 1,
          mx: 'auto',
        },
      })}
    >
      <TablePagination
        component="div"
        count={total}
        page={page - 1}
        onPageChange={(_event, newPage) => apiRef.current.setPage(newPage)}
        rowsPerPage={pageSize}
        rowsPerPageOptions={pageSizeOptions}
        onRowsPerPageChange={(event) => apiRef.current.setPageSize(Number(event.target.value))}
        backIconButtonProps={{
          sx: { display: 'none' },
        }}
        nextIconButtonProps={{
          sx: { display: 'none' },
        }}
      />
      <Pagination
        color="primary"
        count={Math.ceil(total / pageSize)}
        page={page}
        onChange={(_event, newPage) => apiRef.current.setPage(newPage - 1)}
      />
    </Stack>
  )
}

type DataTableProps = {
  data: GridValidRowModel[]
  total?: number
  columns: GridColDef[]
  // controls both pagination and sorting
  paginationMode?: 'client' | 'server'
  page?: number
  pageSize?: number
  pageSizeOptions?: number[]
  onPageChange?: (page: number) => void
  onPageSizeChange?: (pageSize: number) => void
  orderBy?: string
  orderDirection?: 'asc' | 'desc'
  onOrderChange?: (newOrder: { field?: string; sort?: 'asc' | 'desc' }) => void
  sx?: SxProps
}

export default function DataTable(props: DataTableProps) {
  const {
    data,
    columns,
    paginationMode = 'client',
    onPageChange = (newPage) => {
      setInternalPage(newPage)
    },
    pageSizeOptions = [12, 24, 48],
    onPageSizeChange = (newPageSize) => {
      setInternalPage(1) // prevents MUI from throwing an error for a page being out of range
      setInternalPageSize(newPageSize)
    },
    orderBy,
    orderDirection,
    onOrderChange,
    sx,
  } = props
  const total = props.total || data?.length

  // doing this so that we can have a controlled and uncontrolled version of the page and pageSize depending on paginationMode
  // client mode will control its own state while server forces the internal state to match the props
  const [internalPage, setInternalPage] = useState(props.page || 1)
  useEffect(() => {
    if (paginationMode === 'client' || !props.page) return
    setInternalPage(props.page)
  }, [props.page])
  const [internalPageSize, setInternalPageSize] = useState(props.pageSize || pageSizeOptions[0])
  useEffect(() => {
    if (paginationMode === 'client' || !props.pageSize) return
    setInternalPageSize(props.pageSize)
  }, [props.pageSize])

  return (
    <Box style={{ width: '100%' }} sx={sx}>
      <DataGrid
        autoHeight
        rows={data}
        getRowId={(row) => {
          // combine the values of all the columns to make a unique id
          return columns.reduce((acc, col) => {
            return `${acc}${JSON.stringify(row[col.field])}`
          }, '')
        }}
        rowCount={total}
        columns={columns}
        disableColumnMenu // doesn't pay well with parent component state so disabling for now
        disableSelectionOnClick
        hideFooterSelectedRowCount
        paginationMode={paginationMode}
        sortingMode={paginationMode}
        page={internalPage - 1} // have to adjust since mui is 0 based but that is a bit unusual for pagination
        pageSize={internalPageSize}
        rowsPerPageOptions={pageSizeOptions}
        onPageChange={(newPage) => {
          if (typeof onPageChange !== 'function') return
          onPageChange(newPage + 1) // have to adjust since mui is 0 based but that is a bit unusual for pagination
        }}
        onPageSizeChange={onPageSizeChange}
        sortModel={orderBy ? [{ field: orderBy, sort: orderDirection }] : undefined}
        onSortModelChange={(newSort) => {
          if (typeof onOrderChange !== 'function') return
          const formattedNewSort: {
            field?: string
            sort?: 'asc' | 'desc'
          } = {
            field: newSort[0]?.field ? newSort[0].field : undefined,
            sort: newSort[0]?.sort || undefined,
          }
          onOrderChange(formattedNewSort)
        }}
        components={{
          Pagination: CustomPagination,
        }}
        componentsProps={{
          pagination: {
            total,
            page: internalPage,
            pageSize: internalPageSize,
            pageSizeOptions,
          },
        }}
      />
    </Box>
  )
}
