import { createTheme } from '@mui/material'
import { indigo } from '@mui/material/colors'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    zero: true // custom breakpoint
    xxxs: true // custom breakpoint
    xxs: true // custom breakpoint
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    error: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    error?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    error: true
  }
}

let AppTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#303c6c', contrastText: '#fff' },
    secondary: { main: '#f4976c', contrastText: '#000' },
    text: {
      secondary: indigo[800],
    },
  },
  breakpoints: {
    values: {
      zero: 0,
      xxxs: 300,
      xxs: 390,
      xs: 425,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})

// this section is for theme props that rely on palette or breakpoints defined above dynamically
AppTheme = createTheme(AppTheme, {
  typography: {
    error: {
      color: AppTheme.palette.error.main,
      fontWeight: 700,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
  },
})

export default AppTheme
