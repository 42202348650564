import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './App.css'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Auth0ProviderWithHistory from './providers/Auth0ProviderWithHistory'
import { AppUpdateProvider } from './providers/AppUpdateContext'
import { AppLoadingProvider } from './providers/AppLoadingContext'
import { AuthStateProvider } from './providers/AuthStateContext'

import AppLayout from './providers/AppLayout'
import PrivateRouteWrapper from './components/PrivateRouteWrapper/PrivateRouteWrapper.component'

import Home from './routes/Home/Home.route'
import HospitalSearch from './routes/Hospitals/HospitalSearch/HospitalSearch.route'
import AgencySearch from './routes/Agencies/AgencySearch/AgencySearch.route'
import Login from './routes/Login/Login.route'
import Logout from './routes/Logout/Logout.route'
import Unauthorized from './routes/Unauthorized/Unauthorized.route'
import HospitalDetail from './routes/Hospitals/HospitalDetail/HospitalDetail.route'
import AgencyDetail from './routes/Agencies/AgencyDetail/AgencyDetail.route'
import NotFound from './routes/NotFound/NotFound.route'

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <AppUpdateProvider>
          <AppLoadingProvider>
            <AuthStateProvider>
              <AppLayout>
                <Routes>
                  <Route path="/unauthorized" element={<Unauthorized />} />
                  <Route path="/login/redirect/:redirect" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/logout" element={<Logout />} />
                  <Route path="/redirect" element={<div>Redirecting...</div>} />
                  <Route path="/404" element={<NotFound />} />
                  {/* BE SURE TO WRAP ALL NEW ROUTES IN PrivateRouteWrapper */}
                  <Route
                    path="/"
                    element={
                      <PrivateRouteWrapper>
                        <Home />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="/hospitals"
                    element={
                      <PrivateRouteWrapper>
                        <HospitalSearch />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="/hospitals/:id"
                    element={
                      <PrivateRouteWrapper>
                        <HospitalDetail />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="/agencies"
                    element={
                      <PrivateRouteWrapper>
                        <AgencySearch />
                      </PrivateRouteWrapper>
                    }
                  />
                  <Route
                    path="/agencies/:id"
                    element={
                      <PrivateRouteWrapper>
                        <AgencyDetail />
                      </PrivateRouteWrapper>
                    }
                  />
                  {/* Default route to /404 */}
                  <Route
                    path="*"
                    element={<Navigate to="/404" state={{ originalUrl: window.location.pathname }} replace />}
                  />
                </Routes>
              </AppLayout>
            </AuthStateProvider>
          </AppLoadingProvider>
        </AppUpdateProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  )
}

export default App
